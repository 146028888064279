* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
}

@font-face {
  font-family: KS-extrabold;
  src: url("../src/assets/fonts/Kumbh_Sans/KumbhSans-ExtraBold.ttf");
}
@font-face {
  font-family: KS-bold;
  src: url("../src/assets/fonts/Kumbh_Sans/KumbhSans-Bold.ttf");
}
@font-face {
  font-family: S-bold;
  src: url("../src/assets/fonts/Satoshi_Complete/Satoshi-Bold.otf");
}
@font-face {
  font-family: S-medium;
  src: url("../src/assets/fonts/Satoshi_Complete/Satoshi-Medium.otf");
}
@font-face {
  font-family: S-regular;
  src: url("../src/assets/fonts/Satoshi_Complete/Satoshi-Regular.otf");
}
@font-face {
  font-family: S-light;
  src: url("../src/assets/fonts/Satoshi_Complete/Satoshi-Light.otf");
}

h1,
h2 {
  font-family: S-bold, sans-serif !important;
}
h3,
h4 {
  font-family: KS-bold, sans-serif !important;
}
h5 {
  font-family: S-bold, sans-serif !important;
}
h6 {
  font-family: S-medium, sans-serif !important;
}
p,
a {
  font-family: S-regular, sans-serif !important;
}
.w-full {
  width: 100%;
}
.form-label {
  margin-bottom: 0.3rem !important;
  font-size: 12px !important;
}
.btn {
  font-style: normal !important;
  font-weight: 300 !important;
  font-size: 14px !important;
}
.btn-primary {
  background-color: #007da3 !important;
  border-radius: 4px !important;
  border-color: none !important;
  border: none !important;
  color: #fff !important;
}
.btn-outline-primary {
  border-color: #007da3 !important;
  border-radius: 4px !important;
  color: #007da3 !important;
}
.btn-outline-primary:hover {
  background: none !important;
}
.form-control {
  padding-right: 2.6em !important;
}
.form-control.is-invalid,
.was-validated .form-control:invalid {
  background-image: none !important;
}
.pagination {
  gap: 8px;
}
.pagination-item {
  border-left: 1px solid #c1c1c1 !important;
}
input::placeholder {
  font-size: 0.8em !important;
}
.dropdown-toggle {
  border-color: #007da3 !important;
  border-radius: 4px !important;
  color: #007da3 !important;
  background-color: none !important;
}
.dropdown-toggle::after {
  display: none !important;
}
textarea {
  resize: none;
  font-size: 14px !important;
  font-weight: 300 !important;
}
input[type="text"] {
  font-size: 14px !important;
  font-weight: 300 !important;
}
input[type="search"] {
  font-size: 14px !important;
  font-weight: 300 !important;
}
input[type="email"] {
  font-size: 14px !important;
  font-weight: 300 !important;
}
.check-view-request {
  background: #e9f6fb;
  border: 1px solid #005e7a;
  color: #005e7a;
  font-size: 16px !important;
  line-height: 24px !important;
  padding: 80px, 16px, 8px, 16px !important;
  font-weight: 500 !important;
}
.NId {
  color: #003e51;
  font-size: 20px !important;
  font-weight: 700;
  line-height: 24px !important;
}
.supporting-doc {
  border: 1px solid #d9dde3;
  padding: 16px;
  border-radius: 8px;
}

.delete-btn img {
  width: 24px;
  cursor: pointer;
  justify-content: space-between;
  gap: 10px;
}
.verify,
.candidate {
  border: 1px solid #d9dde3;
  border-radius: 8px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  /* padding: 24x; */
}
.form-check-input {
  width: 32px;
  height: 32px;
}

.form-check-input:checked {
  background-color: #2d4875 !important;
}
.verify-text {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: #003e51;
}
.verify-input {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #003e51 !important;
}
.check-view-btn {
  background: #007da3;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  font-family: S-bold;
  border: 1px solid #005e7a;
  padding: 8px, 16px, 8px, 16px !important;
}

.check-view-btn:hover {
  background: #048ab3;
  color: #fff;
}

.se-resizing-bar.sun-editor-common.se-resizing-none {
  display: none !important;
}
.tb th {
  font-size: 11px !important;
}
.tb td {
  font-size: 11px;
}
